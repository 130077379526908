import VueRouter from 'vue-router'

interface NavItem {
  name: string
  path: string
  isCta?: boolean
}

export function getNavItems(router: VueRouter, header = true): NavItem[] {
  if (!router.options.routes) return []
  const headerOrFooter: string = header ? 'isHeader' : 'isFooter'
  return router.options.routes
    .filter((route) => route.meta?.[headerOrFooter])
    .map((route) => {
      if (route.name) {
        return {
          name: route.meta.title || route.name,
          path: route.path,
          isCta: route.meta?.isCta,
        }
      }
      return {
        name: fallBackRouteName(route.path),
        path: route.path,
        isCta: route.meta?.isCta,
      }
    })
}

function fallBackRouteName(path: string): string {
  const splittedArray = path.split('/')
  const length = splittedArray.length
  if (splittedArray[length - 1] === '' && length > 1) {
    return cap(splittedArray[length - 2])
  }
  return cap(splittedArray[length - 1])
}

function cap(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function transformLinks(text: string) {
  const linkExpr = /\[\[(.*?)\]\]/gi
  const linkValueExpr = /(\s+\|\s+)/
  return text.replace(linkExpr, (expr: string, value: string) => {
    const parts = value.split(linkValueExpr)
    const link = `<a href="${parts[0]}" target="_blank" rel="noopener noreferrer">${parts[2]}</a>`

    return link
  })
}
