












import { Component, Vue } from 'vue-property-decorator'
import Intro from '@/views/Intro.vue'
import UX from '@/views/UX.vue'
import Benefits from '@/views/Benefits.vue'
import Numbers from '@/views/Numbers.vue'
import Team from '@/views/Team.vue'
import FAQ from '@/views/FAQ.vue'
@Component({
  components: { Intro, UX, Benefits, Numbers, Team, FAQ },
})
export default class Home extends Vue {}
