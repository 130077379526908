























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconCard extends Vue {
  @Prop({ required: true })
  private readonly icons!: IconWithCard[]
}
