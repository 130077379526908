




























































































import { Component, Vue } from 'vue-property-decorator'
// import { getNavItems } from '@/helper/helper'
// import FooterList from '@/components/footer/FooterList.vue'
import FooterNewsletter from '@/components/footer/FooterNewsletter.vue'
import Button from '@/components/base/Button.vue'
import BaseModal from '@/components/base/modal/BaseModal.vue'
import BaseModalHeader from '../base/modal/BaseModalHeader.vue'
import { FadeTransition } from 'vue2-transitions'

@Component({
  components: {
    // FooterList,
    FooterNewsletter,
    Button,
    BaseModal,
    BaseModalHeader,
    FadeTransition,
  },
})
export default class Footer extends Vue {
  showModal = false
  show = false
  modalObject: any = {}
  modalKey: string = ''

  buttonList: IButton[] = [
    {
      modalKey: 'freeDemo',
      key: 'footer.buttons.freeDemo',
      link: '/free-demo',
    },
    {
      modalKey: 'contact',
      key: 'footer.buttons.contact',
      link: 'contact',
      openModal: true,
    },
    {
      modalKey: 'job',
      key: 'footer.buttons.job',
      link: 'job',
      openModal: true,
    },
  ]

  modalList: any = {
    contact: {
      links: ['mailto:info@lobo.at', 'tel:+43 664 123 456 8'],
      icon: 'IconContact',
      key: 'contact',
    },
    job: { links: ['mailto:job@lobo.at'], icon: 'IconJob', key: 'job' },
  }

  handlerOpenModal(button: IButton) {
    this.modalKey = button.modalKey!
    // this.$modal.show('footer-modal')
    this.modalObject = { ...this.modalList[this.modalKey] }
    this.show = true
  }

  discard() {
    this.show = false
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }
  // footerList1: IFooterList = {
  //   title: 'About',
  //   items: [
  //     {
  //       text: 'About us',
  //       path: '/about',
  //     },
  //     {
  //       text: 'Want to work for us?',
  //       path: '/work',
  //     },
  //     {
  //       text: 'Contact',
  //       path: '/contact',
  //     },
  //   ],
  // }

  // footerList2: IFooterList = {
  //   title: 'Features',
  //   items: [
  //     {
  //       text: 'Admin Experience',
  //       path: '/experience-admin',
  //     },
  //     {
  //       text: 'Driver Experience',
  //       path: '/experience-driver',
  //     },
  //     {
  //       text: 'Customer Experience',
  //       path: '/experience-customer',
  //     },
  //   ],
  // }

  // footerList3: IFooterList = {
  //   title: 'Legal',
  //   items: [
  //     {
  //       text: 'Terms & Conditions',
  //       path: '/terms',
  //     },
  //     {
  //       text: 'Cookies Policy',
  //       path: '/cookies',
  //     },
  //     {
  //       text: 'Privacy Policy',
  //       path: '/privacy',
  //     },
  //   ],
  // }

  // footerListSocial: IFooterList = {
  //   title: 'Social',
  //   items: [
  //     { text: 'IconLinkedin', path: '/linkedin1' },
  //     { text: 'IconFacebook', path: '/linkedin2' },
  //     { text: 'IconInstamgram', path: '/linkedin3' },
  //     { text: 'IconTwitter', path: '/linkedin4' },
  //   ],
  // }

  get year() {
    return new Date().getFullYear()
  }
  // addLinkToFooterText({ link, text }: FooterItem): string {
  //   if (link === 'link')
  //     return `<a href="/${text.toLowerCase()}" class="text-white">${text}</a>`
  //   else if (link) {
  //     return `<a href="${link}:${text}">${text}</a>`
  //   }
  //   return text
  // }

  // private get navItems(): NavItem[] {
  //   return getNavItems(this.$router, false)
  // }
}
