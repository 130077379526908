import { transformLinks } from '@/helper/helper'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)
declare module 'vue/types/vue' {
  interface Vue {
    $tLink(key: string): string
  }
}

export default new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'en',
  messages: {
    en: require('@/locales/en.json'),
    de: require('@/locales/de.json'),
  },
})

const tLink = {
  // eslint-disable-next-line
  install(Vue: any) {
    Vue.prototype.$tLink = function (key: string): string {
      //In this example links are structured as follows [[url | text]]

      const translatedString: string = this.$t(key)

      return transformLinks(translatedString)
    }
  },
}

export { tLink }
