









import { Component, Emit, Vue } from 'vue-property-decorator'

@Component
export default class BaseIconClose extends Vue {
  @Emit()
  clickHandler(): void {}
}
