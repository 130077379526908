
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import FieldError from '@/components/base/FieldError.vue'

@Component({ components: { FieldError }})
export default class Input extends Vue {
  @Prop({ required: true })
  private readonly field!: field

  @Emit()
  change(payload: any) {
    return { field: this.field.key, value: payload }
  }
}
