

























import { Component, Vue } from 'vue-property-decorator'
import IconCard from '@/components/layout/IconCard.vue'
@Component({
  name: 'Platform',
  components: {
    IconCard,
  },
})
export default class Benefits extends Vue {
  // @Prop() private msg!: string;
  icons: IconWithCard[] = [
    {
      icon: 'IconBilling',
      key: 'benefits.icons.moneySavingAccounting',
    },
    {
      icon: 'IconNavigation',
      key: 'benefits.icons.intelligentNavigation',
    },
    {
      icon: 'IconWebApp',
      key: 'benefits.icons.webbasedApplication',
    },
    {
      icon: 'IconApi',
      key: 'benefits.icons.apiReady',
    },
    {
      icon: 'IconDigitalSignature',
      key: 'benefits.icons.digitalSignature',
    },
    {
      icon: 'IconPod',
      key: 'benefits.icons.pod',
    },
    {
      icon: 'IconTemplates',
      key: 'benefits.icons.templatesStandingOrders',
    },
    {
      icon: 'IconCustomerPortal',
      key: 'benefits.icons.customerPortal',
    },
    {
      icon: 'IconPricingModel',
      key: 'benefits.icons.pricingModels',
    },
  ]
}
