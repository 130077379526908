









import { Component, Vue } from 'vue-property-decorator'
import IconCardBelow from '@/components/layout/IconCardBelow.vue'

@Component({
  components: {
    IconCardBelow,
  },
})
export default class Numbers extends Vue {
  icons: IconWithCard[] = [
    {
      icon: 'IconBike',
      title: '3 Mill. km',
      key: 'Distance traveled per year, 95% by bike',
    },
    {
      icon: 'IconCo2',
      title: '628.000 kg',
      key: 'less CO2-emissions per year',
    }, //<span class="inline-block align-text-bottom 2</span>
    {
      icon: 'IconCustomers',
      title: '34 Customers',
      key: 'in Europe with 770 active users per week',
    },
    {
      icon: 'IconEurope',
      title: '12 Countries',
      key: 'are delighted by services based on lobo',
    },
    {
      icon: 'IconStops',
      title: '1 Mill. stops',
      key: 'per year, one every 2 minutes',
    },
  ]
}
