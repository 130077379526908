



























import { Component, Prop, Vue } from 'vue-property-decorator'
import ModalLayout from '@/components/layout/ModalLayout.vue'

@Component({
  components: {
    ModalLayout,
  },
})
export default class BaseModal extends Vue {
  @Prop({ required: true })
  private readonly modalName!: string
  @Prop({ default: 628 })
  private readonly width!: number

  beforeClose(event: Event): void {
    this.$emit('before-close', event)
  }

  beforeOpen(event: Event): void {
    this.$emit('before-open', event)
  }
}
