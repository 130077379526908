













import { Component, Vue } from 'vue-property-decorator'
import footer_ from '@/components/footer/footer2.vue'
import header_ from '@/components/header/header.vue'

@Component({
  components: { footer_, header_ },
})
export default class App extends Vue {}
