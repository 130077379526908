






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Input extends Vue {
  @Prop({ required: true })
  private readonly fieldError!: string
}
