







































import { v4 as uuidV4 } from 'uuid'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { transformLinks } from '@/helper/helper'

@Component
export default class FaqCards extends Vue {
  @Prop({ required: true })
  private readonly items!: FaqCard[]

  itemsWithIds: Required<FaqCard[]> = []

  mounted(): void {
    this.setItemsWithIds()
  }
  setItemsWithIds(): void {
    this.itemsWithIds = this.items.map((item) => {
      return { ...item, id: uuidV4(), open: false }
    })
  }

  change(card: FaqCard): void {
    this.itemsWithIds = this.itemsWithIds.map((item) => {
      return item.id === card.id ? { ...item, open: !card.open } : item
    })
  }
  transformLinksLocal(key: string): string {
    return transformLinks(key)
  }
}
