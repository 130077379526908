












import { Component, Vue } from 'vue-property-decorator'
import TeamCards from '@/components/layout/TeamCards.vue'

@Component({
  components: {
    TeamCards,
  },
})
export default class Team extends Vue {
  employees: TeamCard[] = [
    {
      icon: 'Juergen.jpg',
      name: 'Jürgen',
      title: 'Founder, Back End',
      text: 'Mein Leben: Auf dem Bullitt cruisen, oder an lobo arbeiten.',
    },
    {
      icon: 'Florian.jpg',
      name: 'Florian',
      title: 'Front End, UX-Engineer',
      text:
        'Einen Vorsprung im Leben hat, wer da anpackt, wo die anderen erst einmal reden.',
    }, // John F. Kennedy
    {
      icon: 'Philipp.jpg',
      name: 'Philipp',
      title: 'Grafik, UX-Design',
      text: 'Hübsch allein reicht nicht, es muss auch geil bedienbar sein.',
    },
  ]
}
