import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/styles.css'
import '@/plugins/autoload'
import 'vue-class-component/hooks'

import i18n, { tLink } from '@/plugins/i18n'
// import i18nHelper from '@/plugins/i18n'
// Vue.use(i18nHelper)
Vue.use(tLink)

import VModal from 'vue-js-modal'
Vue.use(VModal)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

Vue.config.productionTip = false

// Create VueI18n instance with options
// const i18n = 

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
