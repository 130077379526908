



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import CInput from '@/components/base/Input.vue'

@Component({ components: { CInput } })
export default class Form extends Vue {
  @Prop({ required: true })
  private readonly button!: IButton

  @Emit('should-open-modal')
  openModal() {
    return this.button
  }
}
