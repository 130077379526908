




























import { Component, Vue, Prop } from 'vue-property-decorator'
import BaseIconClose from '@/components/base/BaseIconClose.vue'

@Component({
  name: 'ModalLayout',
  components: {
    BaseIconClose,
  },
})
export default class ModalLayout extends Vue {
  @Prop({ required: true })
  private readonly modalName!: string
  @Prop({ default: 628 })
  private readonly width!: number
  @Prop({ default: false, type: Boolean })
  private readonly showCloseIcon!: boolean

  beforeClose(event: Event): void {
    this.$emit('before-close', event)
  }

  beforeOpen(event: Event): void {
    this.$emit('before-open', event)
  }

  discard(): void {
    this.$modal.hide(this.modalName)
  }
}
