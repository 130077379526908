

























import { Component, Vue } from 'vue-property-decorator'
import FaqCards from '@/components/layout/FaqCards.vue'

@Component({
  components: {
    FaqCards,
  },
})
export default class FAQ extends Vue {
  items: FaqCard[] = (this.$t('faq.questions') as unknown) as FaqCard[]
}
