





























import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class FooterNewsletter extends Vue {
  private successText: string = 'Thx for your interest!'
  private showSuccessText: boolean = false

  submitForm(ev: Event) {
    // const target = ev.target as HTMLElement
    this.showSuccessText = true
  }
}
