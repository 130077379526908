import Vue from 'vue'
import { RouteConfig, Route, NavigationGuardNext } from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '@/plugins/i18n'
export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
      console.log('from:', from)
      console.log('to:', to.query, to)
      if (to.query?.lang) {
        i18n.locale = to.query.lang as string
      }
      next()
      // ...
    },
  },
  {
    path: '/free-demo',
    name: 'FreeDemo',
    component: () =>
      import(/* webpackChunkName: "freeDemo" */ '../views/FreeDemo.vue'),
    meta: {
      isHeader: true,
      title: 'FreeDemo',
      isCta: true,
    },
  },
  {
    path: '/free-demo-thx',
    name: 'FreeDemoThx',
    component: () =>
      import(/* webpackChunkName: "freeDemoThx" */ '../views/FreeDemoThx.vue'),
    meta: {
      isHeader: false,
      title: 'FreeDemoThanks',
      isCta: false,
    },
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () =>
      import(/* webpackChunkName: "impressum" */ '../views/Imprint.vue'),
    meta: {
      isFooter: true,
    },
  },
  // catchall for catching 404 NotFound error
  // https://router.vuejs.org/guide/essentials/history-mode.html#caveat
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
  },
]
