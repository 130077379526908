






















































import { Component, Vue } from 'vue-property-decorator'
import { FadeTransition } from 'vue2-transitions'

@Component({ components: { FadeTransition } })
export default class UX extends Vue {
  // @Prop() private msg!: string;
  private activeCard: number = 0

  private cards = [
    {
      key: 'owner',
      numberOfBullets: 9,
    },
    {
      key: 'dispatcher',
      numberOfBullets: 9,
    },
    {
      key: 'driver',
      numberOfBullets: 6,
    },
    {
      key: 'customer',
      numberOfBullets: 8,
    },
    {
      key: 'accountant',
      numberOfBullets: 7,
    },
  ]

  setActive(index: number): void {
    this.activeCard = index
  }
}
