








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseModalHeader extends Vue {
  @Prop({ required: true, default: '', type: String })
  private readonly title!: string
}
